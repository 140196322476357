import styled from 'styled-components';

import {
  GlobalHero
} from '../Global';
import MediaBreakDown from '../../styles/MediaBreakDown';
import {
  NavWrapper,
} from '../Header/Nav';

const ViewPortWrapper = styled.div`
  height: calc(100vh - 90px); // 90px is the header height aprox.
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MediaBreakDown.lg`
    height: initial;
  `}
`;
ViewPortWrapper.displayName = 'ViewPortWrapper';

const Hero = styled(GlobalHero)`
  padding: 20px 0 20px;

  .hero-text {
    h1 {
      margin: initial;
      max-width: initial;
    }
  }
  ${MediaBreakDown.lg`
    padding: 50px 0;

    .hero-text {
      max-width: 520px;
    }
  `}
  ${MediaBreakDown.sm`
    padding: 30px 0;
  `}
`;
Hero.displayName = 'Hero';

const OptionsMenuWrapper = styled.div`
  .title {
    text-align: center;
  }

  ${NavWrapper} {
    display: flex;
    justify-content: center;
    max-width: initial;
  }

  ${MediaBreakDown.lg`
    display: none;
  `}
`;
OptionsMenuWrapper.displayName = 'OptionsMenuWrapper';

const LinksWrapper = styled.div`
  margin-top: 10px;

  &.floating {
    margin: 0;
    position: fixed;
    top: 60px;
    z-index: 5;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    max-height: 35px;
    padding: 3px 0;

    a {
      padding: 7px 10px;
    }
  }

  a {
    font-size: 1.125rem;
  }
`;
LinksWrapper.displayName = 'LinksWrapper';

export {
  ViewPortWrapper,
  Hero,
  OptionsMenuWrapper,
  LinksWrapper,
};
export default ViewPortWrapper;
