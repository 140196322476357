import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LinksWrapper,
} from './index.style';
import {
  NavWrapper,
} from '../Header/Nav';
import {
  NavLinkExternal,
} from '../Header/HeaderStyles';
import constants from '../../constants';

const SectionMenu = () => {
  const [wrapperClass, _setWrapperClass] = useState('');
  const [itemSelected, setItemSelected] = useState('');
  // This is to read the updated value from the scroll event:
  const wrapperClassRef = useRef(wrapperClass);
  // This is to update both values:
  const setWrapperClass = (data) => {
    wrapperClassRef.current = data;
    _setWrapperClass(data);
  };

  const linksBarRef = useRef(null);
  let scrollYPositionOfFloating = 0;

  useEffect(() => {
    const handleScroll = () => {
      const {
        top,
      } = linksBarRef.current.getBoundingClientRect();
  
      if (top <= 60 && !wrapperClassRef.current) {
        setWrapperClass('floating');
        scrollYPositionOfFloating = window.scrollY;
      }
      else if (
        wrapperClassRef.current
        && scrollYPositionOfFloating > 0
        && window.scrollY < window.innerHeight
        && window.scrollY < scrollYPositionOfFloating
      ) {
        setWrapperClass('');
      }

      /** SELECT MENU ITEMS ON SCROLLING */
      const getSelectedItem = (sectionId, otherValue) => {
        const topLimit = 60;
        const element = window.document.getElementById(sectionId);
        
        return (element && element.getBoundingClientRect().top < topLimit)
          ? sectionId
          : otherValue;
      };
      
      let itemLinkSelected = '';
      itemLinkSelected = getSelectedItem(constants.DOM_ID.SPACES, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.TASKS, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.BOARD, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.CALENDAR, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.REPORTS, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.HOME, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.FORMS, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.NOTES, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.TEMPLATES, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.MEETINGS, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.EXTENSIONS, itemLinkSelected);
      itemLinkSelected = getSelectedItem(constants.DOM_ID.ALL_FEATURES, itemLinkSelected);

      setItemSelected(itemLinkSelected);
    };

    window.addEventListener('scroll', handleScroll);

    const cleanUp = () => {
      window.removeEventListener('scroll', handleScroll);
    };
    return cleanUp;
  }, []);

  return (
    <LinksWrapper
      ref={linksBarRef}
      className={wrapperClass}
    >
      <NavWrapper>
        <ul>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.SPACES ? 'active' : ''}
              href={`#${constants.DOM_ID.SPACES}`}
            >
              Spaces
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.TASKS ? 'active' : ''}
              href={`#${constants.DOM_ID.TASKS}`}
            >
              Tasks
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.BOARD ? 'active' : ''}
              href={`#${constants.DOM_ID.BOARD}`}
            >
              Board
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.CALENDAR ? 'active' : ''}
              href={`#${constants.DOM_ID.CALENDAR}`}
            >
              Calendar
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.REPORTS ? 'active' : ''}
              href={`#${constants.DOM_ID.REPORTS}`}
            >
              Reports
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.HOME ? 'active' : ''}
              href={`#${constants.DOM_ID.HOME}`}
            >
              Home
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.FORMS ? 'active' : ''}
              href={`#${constants.DOM_ID.FORMS}`}
            >
              Forms
            </NavLinkExternal>
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.MEETINGS ? 'active' : ''}
              href={`#${constants.DOM_ID.MEETINGS}`}
            >
              Meetings
            </NavLinkExternal>
          </li>
          <li>
            |
          </li>
          <li>
            <NavLinkExternal
              className={itemSelected === constants.DOM_ID.ALL_FEATURES ? 'active' : ''}
              href={`#${constants.DOM_ID.ALL_FEATURES}`}
            >
              All features
            </NavLinkExternal>
          </li>
        </ul>
      </NavWrapper>
    </LinksWrapper>
  );
};
export default SectionMenu;
