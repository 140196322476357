/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  StaticQuery,
  graphql,
} from 'gatsby';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.css';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../Container';
import Image from '../Image';
import CallToAction from '../CallToAction';
import {
  HeroHeading,
  SectionTitle,
  BodyTextBig,
} from '../Global';
import SectionMenu from './section-menu';
import Testimonials from '../Testimonials';
import playImg from './play.png';
// Style
import {
  ViewPortWrapper,
  Hero,
  OptionsMenuWrapper,
} from './index.style';
// Constants
import constants from '../../constants';

const TrialHeaderSection = ({
  data,
  CtaLink,
  CtaText,
}) => {
  const [isOpen, setOpen] = useState(false);

  // HANDLERS:
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const testimonialsComponent = (
    <Testimonials
      data={data.allContentfulTestimonial.edges}
      size={constants.SIZES.SMALL}
    />
  );
  return (
    <ViewPortWrapper>
      {/* When using the <ViewPortWrapper> we should have 2 children: */}
      {/* 1st child */}
      <Container>
        <Hero>
          <div className="hero-text">
            <HeroHeading>
              Unlock the full power of Workast
            </HeroHeading>
            <BodyTextBig mt="30px" mb="45px">
              Workast helps teams on Slack organize their work.
              Delegate tasks, manage projects, run meetings, streamline
              processes and gain visibility of what your team is working on.
            </BodyTextBig>
            <div className="button-wrap">
              <CallToAction
                text={CtaText}
                link={CtaLink}
              />
              <div className="label">No credit card required</div>
            </div>
          </div>
          <div className="hero-img">
            <div className="cta-wrapper">
              <CallToAction
                text={CtaText}
                link={CtaLink}
              />
              <div className="label">No credit card required</div>
            </div>
            {typeof ModalVideo !== 'object' && (
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="fwimHyFdh-I"
                onClose={handleClose}
              />
            )}
            <div
              role="presentation"
              className="img-video-wrapper"
              onClick={handleClick}
            >
              <Image name="trial-home.png" alt="hero screenshot" />
              <img className="play-img" src={playImg} alt="play" />
            </div>
          </div>
        </Hero>
      </Container>
      {testimonialsComponent}
      {/* 2nd child */}
      <OptionsMenuWrapper>
        <SectionTitle className="title">What you can do with Workast?</SectionTitle>
        <SectionMenu />
      </OptionsMenuWrapper>
    </ViewPortWrapper>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TrialHeaderSection.propTypes = {
  CtaLink: PropTypes.string.isRequired,
  CtaText: PropTypes.string.isRequired,
  data: PropTypes.shape({
    allContentfulTestimonial: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            author: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            description: PropTypes.shape({
              description: PropTypes.string.isRequired
            }).isRequired,
            image: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

const TrialHeaderSectionWithQuery = props => (
  <StaticQuery
    query={graphql`
      query TrialHeaderSectionWithQuery {
        allContentfulTestimonial {
          edges {
            node {
              author
              role
              description {
                description
              }
              image {
                gatsbyImageData(width:80)
              }
            }
          }
        }
      }
    `}
    render={data => <TrialHeaderSection {...props} data={data} />}
  />
);

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TrialHeaderSectionWithQuery;
