/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Constants
import constants from '../constants';
// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import TrialHeaderSection from '../components/TrialHeader';
import SpacesSection from './sections/spaces';
import TasksSection from './sections/tasks';
import BoardSection from './sections/board';
import CalendarSection from './sections/calendar';
import ReportsSection from './sections/reports';
import HomeSection from './sections/home';
import FormsSection from './sections/forms';
import NotesSection from './sections/notes';
import TemplatesSection from './sections/templates';
import MeetingsSection from './sections/meetings';
import ExtensionsSection from './sections/extensions';
import AllFeaturesSection from './sections/all-features';
import StartTrialSection from './sections/start-trial';
import CustomFieldsSection from './sections/custom-fields';
import TimelineSection from './sections/timeline';

const TrialPage = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Trial | Workast - Do More"
    />
    <TrialHeaderSection
      CtaText={constants.TRIAL.LINK_TEXT}
      CtaLink={constants.TRIAL.LINK_URL}
    />
    <SpacesSection />
    <TasksSection
      CtaText={constants.TRIAL.LINK_TEXT}
      CtaLink={constants.TRIAL.LINK_URL}
    />
    <BoardSection />
    <CalendarSection />
    <TimelineSection />
    <CustomFieldsSection />
    <ReportsSection />
    <HomeSection
      CtaText={constants.TRIAL.LINK_TEXT}
      CtaLink={constants.TRIAL.LINK_URL}
    />
    <FormsSection />
    <NotesSection />
    <TemplatesSection />
    <MeetingsSection
      CtaText={constants.TRIAL.LINK_TEXT}
      CtaLink={constants.TRIAL.LINK_URL}
    />
    <ExtensionsSection />
    <AllFeaturesSection
      backgroundGray
    />
    <StartTrialSection
      CtaText={constants.TRIAL.LINK_TEXT}
      CtaLink={constants.TRIAL.LINK_URL}
    />
  </Layout>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TrialPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TrialPage;
